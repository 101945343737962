.tags-container {
  display: flex;
  flex-wrap: wrap;
}

.options-container {
  img {
    cursor: pointer;
  }
}

.delete-disabled {
  opacity: 0.4;
}

.clickable {
  cursor: pointer;
}
