.date-dropdown-select {
  display: flex;

  .drop-down {
    // direct override for dropdown package
    .css-yk16xz-control {
      height: 45px;
      border-radius: 5px;
    }

    .css-1pahdxg-control {
      height: 45px;
      border-radius: 5px;
      background-color: hsl(0, 0%, 100%);
      border-color: hsl(0, 0%, 80%);
      box-shadow: none;
    }
  }

  .date-dropdown-select__month {
    width: 50%;
    margin-right: 10px;
  }

  .date-dropdown-select__day {
    width: 20%;
    margin-right: 10px;
  }

  .date-dropdown-select__year {
    width: 30%;
  }
}
