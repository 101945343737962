@import 'src/style/variables';

.btn {
  font: {
    weight: 600;
    size: 14px;
  }
  border: none;
  color: $white-color;
  background-color: $yellow;
  line-height: 24px;
  padding: 0.6rem 1.4rem;
  letter-spacing: 1.39px;
  outline-color: $yellow;
  cursor: pointer;

  &.btn-large {
    width: 330px;
  }
  &.btn-sm {
    padding: 0.4rem 1.4rem;
  }
  &.btn-inverse {
    border: 1px solid $yellow;
    background: $white-color;
    color: $yellow;
  }
  &.btn-clear {
    font-family: $libre-franklin;

    border: none;
    background: none;
    color: #9e9c9a;
  }
  &.btn-mark-resolved {
    font-family: $libre-franklin;

    border: none;
    background: none;
    color: $text-color;
    padding: 0;

    font: {
      weight: 600;
      size: 12px;
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
    outline: none;
    cursor: no-drop;
  }
  &.submit-search-btn {
    line-height: 40px;
    padding: 0 1.4rem;
    height: 40px;
  }
}
