@import 'src/style/font-family';
@import 'src/style/variables';

.review-status__container {
  .review-status__header {
    font-family: $libre-franklin;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $text-color;

    margin-bottom: 25px;
  }

  .review-status__input-container {
    display: flex;
    margin-bottom: 30px;

    label {
      display: inline-block;
      margin-bottom: 10px;

      font-family: Libre Franklin;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 15px;
      color: $grey;
    }

    .review-status__status-checkboxes,
    .review-status__last-reviewed {
      width: 30%;
      margin-right: 10px;
    }

    .review-status__status-checkboxes {
      .checkbox-container {
        margin-bottom: 15px;
      }
    }

    .review-status__last-reviewed--display {
      color: $text-color;
      border: $input-border;
      border-radius: 5px;
      box-sizing: border-box;
      box-shadow: $input-box-shadow;
      font-size: 15px;
      height: 44px;
      line-height: 18px;
      outline: none;
      padding: 13px 16px;
      width: 100%;
    }
  }

  .review-status__status-history {
    margin-bottom: 30px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 0;
      margin-bottom: 10px;
      border: none;
      background: none;

      font-family: Libre Franklin;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;

      color: $yellow;

      img {
        margin-left: 7px;
        transform: rotate(180deg);
      }
    }

    .review-status__status-history-list {
      height: 200px;
      padding: 17px 14px;

      overflow-y: auto;

      background: #fcfcfc;
      border: 1px solid #e7e7e7;
      box-sizing: border-box;
      border-radius: 5px;

      li {
        margin-bottom: 12px;
        color: $grey;

        b {
          color: $text-color;
          font-weight: 600;
          text-transform: capitalize;
        }
      }
    }
  }
}
