@import 'src/style/font-family';
@import 'src/style/variables';

.cob-react-patent-child-select-container {
  display: flex;

  .cob-react-parent-child-select {
    margin-right: 16px;
    min-width: 200px;
  }
}
