.card-header {
  position: relative;
  box-sizing: border-box;

  .card-image {
    filter: brightness(90%);
    min-height: 188px;
    height: 188px;
    width: 256px;
    border-radius: 5px 5px 0 0;
  }

  .card-label {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  .card-type-label {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }

  .location-count {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }

  .cob-checkbox,
  .delete-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  .card-header-icons-container {
    display: flex;
    align-items: center;
    position: absolute;
    left: 10px;
    top: 10px;
    width: calc(100% - 20px);

    .card-header-icons {
      height: 25px;
      margin-right: 10px;

      &.open {
        margin-left: auto;
      }
    }

    .card-review-icons__camera {
      width: 25px;
    }

    .card-review-icons__content {
      width: 15px;
    }

    .card-review-icons__data {
      width: 20px;
      height: 25px;
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(153deg)
        brightness(106%) contrast(101%);
    }

    .card-review-icons__open {
      width: 20px;
      height: 20px;
      color: #fff;
    }

    .card-pin-icon__content {
      height: 20px;
    }
  }

  .card-image-inspector{
    width: 390px;
    height: 450px;
    // height: initial;
    border-radius: initial;
  }
}
