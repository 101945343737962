@import 'src/style/font-family';
@import 'src/style/variables';

.overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: relative;
  display: none;

  &.overlay-show {
    display: table;
  }

  .overlay-innerWrapper {
    float: initial;
    display: table-cell;
    height: 100%;
    vertical-align: middle;
    text-align: center;
  }

  .loader {
    display: inline-block;
    position: relative;
    top: initial;
    left: initial;
  }
}
