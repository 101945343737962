@import 'src/style/variables';
@import 'src/style/font-family';

.flagged-card-banner-container {
  border: 1px solid $red;
  color: $text-color;
  background-color: $white-red-color;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;

  h2 {
    font-family: $libre-franklin;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .flagged-card-close-button-icon {
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
}
