@import 'src/style/variables';

.widget-box {
  align-items: center;
  background-color: $white-color;
  box-shadow: $widget-box-shadow;
  box-sizing: border-box;
  display: flex;
  height: 88px;
  padding: 0 44px;
  justify-content: space-between;

  .action-wrapper {
    display: flex;
    align-items: center;

    .widget-loading-icon {
      height: 20px;
    }
  }

  &.card-list-widget {
    height: 60px;
  }

  &.action-only {
    flex-direction: row-reverse;
  }

  .clickable-action {
    align-items: flex-end;
    display: flex;

    img {
      cursor: pointer;
    }

    .action-label {
      color: $grey;
      font-size: 14px;
      margin-left: 16px;
      line-height: 16px;
    }
  }

  .widget-box-btn {
    margin: 0 8px;
    text-transform: uppercase;
  }
}
