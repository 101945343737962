@import 'src/style/variables';

.multi-dropdown-select-wrapper {
  button {
    padding: 0;
    margin-bottom: 10px;
    border: none;
    background: none;
  }

  .multi-dropdown-select__input-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;

    .multi-dropdown-select__input {
      display: flex;
      flex-direction: column;

      align-items: center;
      margin-bottom: 10px;
      width: 50%;
      margin-right: 10px;

      label {
        width: 100%;
        margin-bottom: 10px;
      }
      .drop-down {
        width: 100%;
      }

      .dropdown-category-input {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;

        .multi-dropdown-select__input-delete {
          display: flex;
          align-items: center;
          justify-content: center;

          button {
            margin-left: 10px;
            margin-bottom: 0;
          }
        }
      }
    }

    .dropdown-type-input {
      width: 50%;
    }
  }

  .multi-dropdown-select__add-link {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Libre Franklin;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    color: $yellow;
  }
}
