@import '../../../style/variables';

.recurrence-selector {
  .drop-down {
    .placeholder {
      font-size: 14px;
      opacity: unset;
    }
  }

  .form-input {
    .react-datepicker-wrapper,
    .react-datepicker__input-container {
      display: block;
    }
  }

  .repeat-mode {
    display: flex;
    justify-content: space-between;

    .end-date-picker {
      padding-right: 15px;
    }

    .form-input {
      width: 50%;
    }

    .DayPickerInput {
      width: 100%;

      input {
        border: none;
        width: 100%;
        font-family: 'Libre Franklin', serif;
        font-size: 14px;
        font-weight: 400;
        text-align: left;

        border: 1px solid #d0d0d0;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 13px 20px;
      }
    }

    .DayPickerInput-OverlayWrapper {
      position: relative;

      .DayPickerInput-Overlay {
        position: absolute;
        height: 275px;
        bottom: 45px;
      }
    }
  }

  .end-date-picker {
    position: relative;

    .end-date-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
      width: 16px;
      height: 16px;
      pointer-events: none;
    }
  }
}

.rc-time-picker {
  .rc-time-picker-input {
    font-size: 14px;
  }
}

.weekdays-selector {
  text-align: left;

  label {
    color: $grey;
  }
}

.recurring-weekdays-selector {
  margin-top: 16px;
}
