@import 'src/style/variables';

.textarea-bar {
  color: $text-color;
  font-family: $libre-franklin;
  border: $input-border;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: $input-box-shadow;
  font-size: 14px;
  line-height: 18px;
  outline: none;
  padding: 13px 16px;
  min-width: 100%;
  width: 100%;

  &.no-resize {
    resize: none;
  }
}
