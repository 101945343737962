.icon-container img {
  cursor: pointer;
}

.delete-disabled {
  opacity: .4;
}

.clickable {
  cursor: pointer;
}
