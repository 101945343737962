// common style here
@import './font-family';
@import './variables';

body {
  @include set-typeface('Libre-Regular');
  font-size: 14px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.page-title {
  @include set-typeface('Libre-medium');
  font-size: 33px;
  margin-bottom: 33px;
  color: $text-color;
}

.error {
  color: $error-color;
  margin: 10px 0;
}

.form-field-wrapper {
  margin-bottom: 36px;
  transition: all ease 0.4s;
}

.form-field-double-comp {
  display: flex;
  align-items: center;

  .form-input:first-child {
    margin-right: 15px;
  }

  .form-input {
    width: 50%;
  }
}

.form-field-multi-comp {
  .form-input__input-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      flex-grow: 1;
    }

    button {
      white-space: nowrap;
      margin-left: 15px;
      margin-right: 0;
    }

    .multi-tag-select-container {
      flex-grow: 1;
    }
  }
}

.form-field-header {
  margin-bottom: 25px;
  font-family: $libre-franklin;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #353029;
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}
