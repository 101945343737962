.events-container {
  .events-content {
    margin: 32px 44px;

    .events-search {
      margin: 24px 0;
    }
  }
  .events-widget {
    position: fixed;
    bottom: 0;
    width: calc(100vw - 222px);
  }

  // .btn-container {
  //   justify-content: center;
  //   .btn {
  //     margin-right: 24px;
  //   }
  // }
}
