@import 'src/style/variables';

.card-banner {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 32px 0 42px;

  .header-details {
    display: flex;
    align-items: center;

    .page-title {
      margin: 0 40px 0 0;
    }
  }

  .card-banner-dropdown-search {
    width: 280px;
    margin-right: 20px;

    .card-banner-dropdown-control {
      display: flex;
      align-items: center;
      font-family: 'Libre Franklin', serif;
      height: 40px;
      border: 1px solid #d0d0d0;
      border-radius: 0px;
      box-sizing: border-box;
      outline: none;
      padding: 0 10px;

      .card-banner-dropdown-placeholder {
        font-family: 'Libre Franklin', serif !important;
        font-weight: 500 !important;
        color: $text-color !important;
        font-size: 12px;
      }

      .card-banner-dropdown-menu {
        width: 280px;
      }
    }
  }
}
