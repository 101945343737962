.places-container {
  .page-content {
    margin: 32px 44px;
  }
  .places-widget {
    position: fixed;
    bottom: 0;
    width: calc(100vw - 222px);
  }

  .btn-container {
    justify-content: center;
    .btn {
      margin-right: 24px;
    }
  }
}
