@import 'src/style/variables';

.custom-calendar {
  object-fit: contain;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
  display: flex;
  border: none;
}

.custom-day {
  padding: 8px;
  border-radius: 50%;
  &:hover {
    background-color: $nav;
    border-radius: 50%;
  }
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  margin-top: 16px;
}

.react-datepicker__header {
  background-color: $white-color;
  border-bottom: 1px solid $grey1;
}

.react-datepicker__current-month {
  padding: 16px;
  border-bottom: none;
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase;
}

.react-datepicker__day-name {
  text-transform: uppercase;
  padding: 0 8px;
}

.react-datepicker__day--selected {
  background-color: $translucent-teal;
  &:hover {
    background-color: $translucent-teal;
  }
}

.react-datepicker__input-container input {
  color: $text-color;
  border: $input-border;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: $input-box-shadow;
  font-size: 15px;
  height: 44px;
  line-height: 18px;
  outline: none;
  padding: 13px 16px;
  width: 100%;
}

.react-datepicker__input-container input::after {
  content: '';
  width: 30px;
  height: 30px;
  background-image: url('../../assets/images/icn-calendar.png');
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  top: 10px;
  right: 5px;
  position: absolute;
  display: inline-block;
}
