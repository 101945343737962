@import './../../../style/variables';

.category-form {
  .new-category-checkbox {
    color: $grey4;

    &.togglers {
      // display: inline-block;
      display: flex;
      box-sizing: border-box;
      width: 50%;
      padding: 2.5rem 0 1rem 0;
      // border-bottom: 2px solid #eeedec;
    }
    &.child-of {
      padding: 2.5rem 0 1rem 0;
    }
  }

  .Dropdown-root {
    &.drop-down {
      margin-bottom: 10px;
    }
    .Dropdown-menu {
      max-height: 120px;
    }
  }

  .form-input-parent-label {
    display: flex;
    // align-i: center;

    img {
      margin-left: 5px;
    }

    .parent-info-tooltip-container {
      left: 765px; // !important;

      width: 229px;
      height: 101px;
      border-radius: 5px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
      opacity: 1;
      padding: 5px;
      // background-color: #ffffff;

      .form-select-input-close-icon {
        margin: 1px;
        width: 10px;
        height: 10px;
        cursor: pointer;
      }

      .form-input-close-icon-container {
        display: flex;
        flex-direction: row-reverse;
      }

      p {
        color: black;
        line-height: 15.54px;
        padding-left: 5px;
      }
    }
  }
}

.switch-container {
  display: flex;
  align-items: center;

  label {
    cursor: pointer;
    padding-top: 2px;
    padding-left: 1rem;
    color: #353029;
  }
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  .action-btn {
    width: 140px;
    &.btn-inverse {
      margin-right: 1.5rem;
    }
  }
}
