@import '../../style/variables';
@import '../../style/font-family';

.place-hours {
  margin-bottom: 32px;
  .hours-range-label {
    color: $grey;
    display: inline-block;
    font-size: 13px;
    margin-bottom: 12px;
  }
  .edit-btn {
    padding: 0;
    margin-bottom: 10px;
    border: none;
    background: none;

    font-family: Libre Franklin;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    color: $yellow;
  }

  .timer {
    align-items: center;
    display: flex;
    margin-bottom: 24px;

    .timer-separator {
      @include set-typeface('Libre-Medium');
      color: $text-color;
      margin: 0 38px;
    }
    > div {
      width: 100%;
      .weekday-timings-container {
        display: flex;
        margin: 5px 0;
        font-size: 16px;
        line-height: 30px;
        .weekday {
          text-transform: capitalize;
          width: 13%;
        }
        .weekday-timings {
          display: flex;
          .time-seperator {
            margin: 0 42px 0 0;
          }
        }
      }
    }
  }
}
