@import 'src/style/variables';
@import 'src/style/font-family';

.no-data-found {
  align-items: center;
  color: $text-color;
  display: flex;
  justify-content: center;
  min-height: inherit;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-15%, 50%);
  .message {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  .sub-message {
    font-size: 16px;
  }
}
