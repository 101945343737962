@import 'src/style/font-family';
@import 'src/style/variables';

.table-row-token {
  display: flex;
  align-items: center;
  height: 20px;
  background-color: $grey9;
  border-radius: 50px;
  color: #7c8e9a;
  padding: 3px 8px;
  margin-right: 6px;
  margin-top: 2px;
  margin-bottom: 2px;
  list-style-type: none;
  cursor: pointer;

  .table-row-token-label {
    font-family: $libre-franklin;
    font-weight: 600;
    color: $text-color;
    font-size: 12px;
    line-height: 14.54px;
    margin: 0px 5px 0px 5px;
  }
}
