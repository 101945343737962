@import 'src/style/font-family';
@import 'src/style/variables';

.card-view-modal {
  background-color: $white-color;
  left: 0;
  height: calc(100% - #{$nav-bar-height});
  position: fixed;
  width: 100vw;
  top: $nav-bar-height;
  z-index: $z-index-card-modal;
  overflow-y: scroll;

  .modal-innerWrapper {
    box-sizing: border-box;
    position: relative;
    display: block;
    height: initial;
    width: 100%;
    float: left;
    padding: 34px 40px 175px 120px;
  }

  .curated-modal-selected {
    .modal-innerWrapper {
      padding: 40px 40px 40px 40px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    .modal-content {
      height: calc(100% - 70px);

      .card-list {
        height: 100%;

        .card-list-content-container {
          height: calc(100% - 80px);
        }
      }
    }
  }

  header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 43px;

    h1 {
      @include set-typeface('Libre-Medium');
      color: $text-color;
      font-size: 33px;
      line-height: 40px;
    }

    img {
      cursor: pointer;
    }
  }
}
