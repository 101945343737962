@import '../../style/variables';

.datepicker-drop-down {
  width: 200px;
  display: inline-block;
  box-sizing: border-box;
  height: 40px;
  position: relative;

  .dropdown-menu {
    background-color: $white-color;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid $grey1;
    box-sizing: border-box;
    height: 40px;

    &:hover {
      border: 1px solid $grey6;
    }
  }

  &.is-open,
  &:focus {
    outline: none;

    .dropdown-menu {
      border: 2px solid #2684ff;
      margin: -1px;
    }
  }
}
