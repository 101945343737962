@import 'src/style/variables';
@import 'src/style/font-family';

.menu {
  background-color: $white-color;
  border-radius: 6px;
  bottom: 0;
  box-shadow: $sub-menu-box-shadow;
  position: absolute;
  transform: translateX(-80%);
  width: 80px;
  opacity: 0;
  transition: all .5s ease-in-out;

  &.show {
    opacity: 1;
  }

  li {
    @include set-typeface('Libre-Medium');
    color: $black-color;
    font-size: 14px;
    line-height: 17px;
    padding: 8px 16px;
    transition: color .2s ease-in-out;

    &:first-of-type {
      padding-top: 16px;
    }

    &:last-of-type {
      padding-bottom: 16px;
    }

    &:hover {
      color: $yellow;
    }
  }
}
