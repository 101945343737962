.modal-header-container {
  display: flex;
  padding: 24px 0 11px 0;

  .cob-checkbox {
    padding: 0 12px;
  }
  .day {
    text-transform: uppercase;
    padding-right: 24px;
  }
  label {
    padding-top: 3px;
  }
}
.delete {
  margin-left: 30px;
  position: relative;
  top: 4px;
  width: 16px;
  cursor: pointer;
}

.btn-inverse {
  margin-right: 16px;
}

.add-more-hours {
  color: #f7bc25;
  text-decoration: underline;
  cursor: pointer;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  margin: 0;
  background-color: white;
  line-height: 18px;
  font-weight: normal;
  letter-spacing: 0;
}

.edit-hours-modal {
  // border-bottom: 1px solid #eeedec;
  padding-bottom: 24px;
  .errors {
    color: #df2f2f;
    margin-bottom: 10px;
    font-size: 14px;
  }
}
