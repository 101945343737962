@import 'src/style/variables';

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $yellow;
  box-sizing: border-box;
  box-shadow: $navbar-box-shadow;
  height: $nav-bar-height;
  padding: 0 40px;
  width: 100vw;
  color: $white-color;
  text-shadow: $nav-title-text-shadow;
  z-index: $z-index-navbar;

  .nav-brand {
    text-transform: uppercase;

    h1 {
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
    }

    span {
      font-size: 10px;
      line-height: 12px;
    }
  }

  .nav-actions {
    align-items: center;
    display: flex;

    h3 {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }

    .nav-btn {
      margin-left: 8px;
    }
  }
}
