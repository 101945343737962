@import 'src/style/variables';
@import 'src/style/font-family';

.fetch-card-details {
  border-bottom: $input-border;
  box-sizing: border-box;
  display: flex;
  padding: 16px 0;

  .img-container {
    display: flex;
    align-items: center;

    .cover-pic {
      box-sizing: border-box;
      width: 260px;
      height: 160px;
      object-fit: cover;
    }
  }

  .content {
    min-width: 500px;
    margin: 0 40px;

    .categories {
      color: $grey4;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 12px;
    }

    .name {
      @include set-typeface('Libre-Medium');
      color: $text-color;
      font-size: 16px;
      margin: 12px 0;
    }

    .details {
      display: flex;

      .cost {
        width: 75px;
      }
    }

    .ratings {
      margin-left: 20px;
    }

    .review-btn {
      margin-top: 12px;
    }

    p {
      color: $text-color;
      font-size: 14px;
      line-height: 16px;
      margin: 6px 0;
    }

    .address-container {
      display: flex;

      .address-list {
        margin-left: 39px;

        p {
          margin: 0px;
        }
      }
    }

    .fscategories {
      display: flex;

      .fscategories-label {
        width: 95px;
      }
    }
  }

  .views-count-container {
    width: -webkit-fill-available;

    .views-count {
      margin-top: 5px;
      margin-right: 15px;
      float: right;

      img {
        margin-right: 5px;
      }
    }
  }
}
