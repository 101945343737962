@import '../../../style/variables';
@import '../../../style/font-family';

.datepicker-drop-down {
  .dropdown-menu {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .display-text {
    @include set-typeface($libre-regular);
    font-weight: 400;
    font-size: 14px;
    padding-left: 12px;
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;
  }

  .display-icon {
    line-height: 26px;
    width: 10px;
  }

  .dropdown-controls {
    display: flex;
  }
}

.svg-wrapper {
  display: flex;
  padding: 8px;
  transition: color 150ms;
  box-sizing: border-box;
}
