@import 'src/style/variables';
@import 'src/style/font-family';

.preview-header {
  align-items: center;
  display: flex;
  justify-content: space-between;

  .preview-title {
    @include set-typeface('Libra-Medium');
    color: $text-color;
    font-size: 24px;
  }

  .close-icon {
    cursor: pointer;
  }
}
