@import 'src/style/variables';

.multi-input-wrapper {
  button {
    padding: 0;
    margin-bottom: 10px;
    border: none;
    background: none;
  }

  .multi-input__input-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .input-bar {
      color: $text-color;
      border: $input-border;
      border-radius: 5px;
      box-sizing: border-box;
      box-shadow: $input-box-shadow;
      font-size: 15px;
      height: 44px;
      line-height: 18px;
      outline: none;
      padding: 13px 16px;
      width: 100%;
    }

    .multi-input__input-delete {
      margin: 5px;
    }

    .multi-input__input-checkbox-component {
      margin-left: 10px;
      white-space: nowrap;
    }
  }
  .multi-input__add-link {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Libre Franklin;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    color: $yellow;
  }
}
