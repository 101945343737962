@import 'style/variables';

.third-party-modal {
  &.modal-show {
    text-align: left;

    overflow: hidden;
    transform: initial;
    max-width: 500px;
    display: inline-block;
    top: initial;
    left: initial;
    position: relative;
    margin: 20px 0;
    max-height: 95vh;
    overflow-y: auto;

    .modal-innerWrapper {
      padding: 0;
    }

    .modal-header {
      margin-bottom: 10px;
    }

    span {
      display: inline-block;
      font-weight: 600;
      margin-bottom: 18px;

      color: $text-color;
      font-size: 14px;
      line-height: 17px;
    }

    label {
      display: inline-block;
      color: $grey;
      font-size: 12px;
      line-height: 14.5px;
      font-weight: 400;

      margin-bottom: 10px;
    }

    .third-party-search-input-wrapper {
      margin-bottom: 25px;

      .third-party-dropdown {
        .third-party-dropdown-control {
          display: flex;
          align-items: center;
          font-family: 'Libre Franklin', serif;
          height: 40px;
          border: 1px solid #d0d0d0;
          border-radius: 0px;
          box-sizing: border-box;
          outline: none;
          padding: 0 10px;

          .third-party-dropdown-placeholder {
            font-family: 'Libre Franklin', serif !important;
            font-weight: 500 !important;
            color: $text-color !important;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.modal-actions {
  display: flex;

  .action-btn {
    width: 140px;

    &.btn-inverse {
      margin-right: 1.5rem;
    }
  }
}
