@import '../../style/variables';
@import '../../style/font-family';

.login {
  background-image: url('../../assets/images/login-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  height: 100vh;
  justify-content: center;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  width: 100vw;

  .logo {
    text-align: center;
  }

  .login-content {
    box-sizing: border-box;
    margin-top: 20vh;
    width: 360px;
  }

  .login-details {
    color: $white-color;
    text-align: center;

    .title {
      @include set-typeface('PT-bold');
      font-size: 40px;
      line-height: 53px;
      margin-bottom: 13px;
    }

    .tag-line {
      @include set-typeface('Libre-semi-bold');
      font-size: 16px;
      line-height: 26px;
      margin-top: 16px;
    }
  }
}
