@import 'src/style/variables';

.card-selection-container {
  box-sizing: border-box;
  max-width: 100%;
  padding-bottom: 5px;

  .selected-card-list {
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
    padding-bottom: 15px;
    margin-left: -10px; padding-left: 10px;

    .selected-card {
      margin-right: 20px;
      height: 460px;

      .card {
        height: calc(100% - 65px);
        margin-bottom: 20px;
      }
        
      .Dropdown-root {
        .Dropdown-menu {
          top: initial;
          bottom: 6px;
          left: 6px;
          width: calc(100% - 12px);
          box-shadow: 0px 0px 6px 0px #464646;

          .option {
            transition: 0.2s ease all;
          }
        }
      }

      .card-image {
        object-fit: cover;
      }
    }
  }

  .modal-header {
    margin-bottom: 10px;
  }
}
