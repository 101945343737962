@import 'src/style/font-family';
@import 'src/style/variables';

@import '../DropDown/DropDown.scss';

.cob-react-multiselect {
  .option {
    outline: none;
    padding: 8px 12px;
    display: flex;
    align-items: center;

    .cob-checkbox {
      height: 18px;
    }

    &.is-selected:not(:hover) {
      background: none;
      color: $black-color;
    }
  }

  .selected-options-container {
    position: relative;
    .placeholder {
      font-size: 14px;
    }
  }

  .selected-options-count {
    @include set-typeface($libre-regular);
    display: inline-block;
    background: $yellow;
    line-height: 20px;
    width: 20px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    font-size: 12px;
    right: 0;
    position: absolute;
  }
}

.option {
  .square {
    input {
      margin: 0 8px;
    }
  }
}
