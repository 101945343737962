@import 'src/style/variables';

.preview-heading {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 24px;
}
.error {
  margin-top: 20px;
  color: $error-color;
}
.uploader-content {
  overflow: auto;
  display: flex;
}

.layout {
  display: flex;
  .add-icon {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
  }
}

.photo-preview-input-wrapper {
  margin-top: 15px;

  .photo-preview-input {
    margin-top: 15px;
    margin-right: 24px;

    label {
      display: inline-block;
      margin-bottom: 12px;
    }

    .input-bar {
      // width: ;
    }

    .photo-credit__input-delete {
      margin: 5px 5px 5px 5px;
    }
  }
}

.upload-error {
  color: $error-color;
  margin-bottom: 10px;
}

.preview-file {
  position: relative;
}

.preview-action {
  position: absolute;
  text-align: center;
  top: 10px;
  right: 30px;
  width: 18px;
  height: 18px;
  border-radius: 10px;
  cursor: pointer;
  background: $white-color;
}

.droppable {
  border: 2px dashed $grey;
}

.dzu-previewImage {
  box-sizing: border-box;
  height: 163px;
  width: 259px;
  max-height: none;
  max-width: none;
  border-radius: 10px;
  margin-right: 24px;
  object-fit: cover;
  &.error {
    border: 2px solid $error-color;
  }

  &.largePreview {
    height: 334px;
    width: 260px;
  }
}

.dzu-previewButton {
  color: $white-color;
}

.dzu-previewContainer {
  margin: 0 10px 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  min-height: 60px;
  z-index: 1;
  box-sizing: border-box;
  height: 161.6px;
  width: 261.6px;
  opacity: 0.6;
  border-radius: 8px;
}

.dzu-submitButtonContainer {
  display: none;
}

.dzu-dropzone {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 163px;
  width: 259px;
  overflow: hidden;
  margin: 0;
  box-sizing: border-box;
  border: none;
  transition: all 0.15s linear;
  opacity: 0.6;

  &.largePreview {
    height: 334px;
    width: 260px;
  }

  .dzu-inputLabel {
    border: 2px dashed $grey;
  }
  .dzu-inputLabelWithFiles {
    border: 2px dashed $grey;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    background: transparent;
    color: transparent;
  }
}

.dzu-previewStatusContainer {
  display: flex;
  align-items: center;
  position: absolute;
}

.smooth-dnd-container {
  min-width: 0px !important; // prevents little space when no photos are uploaded
}
