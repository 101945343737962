@import 'src/style/_variables.scss';

.address-label {
  color: $grey;
  display: inline-block;
  font-size: 13px;
  margin-bottom: 12px;
}

.address-input__wrapper {
  display: flex;
  align-items: center;

  .address-input__input-checkbox-component {
    margin-left: 15px;
    white-space: nowrap;
  }
}

.pac-icon {
  display: none;
}

.pac-container {
  top: 42px;
  z-index: 1006;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  box-sizing: border-box;
  background: $white-color;

  &:after {
    display: none;
  }
}

.pac-item {
  float: left;
  width: 100%;
  padding: 10px;
  font-size: 12px;
  box-sizing: border-box;
  border: none;

  &:hover {
    cursor: pointer;
  }
}
