@import '../../style//variables';

$toggler-height: 18px;

.cob-interactive-table {
  border-radius: 8px;
  border: 1px solid #dcdbd9;
}
.cob-table {
  box-sizing: border-box;
  border-style: hidden;
  width: 100%;
  font-family: 'arial', sans-serif;

  td,
  th {
    padding: 0 1.5rem;
    vertical-align: middle;
    text-align: center;
    &:first-child {
      text-align: left;
    }
    &:nth-last-child(2) {
      text-align: left;
    }
  }
  tr {
    height: 52px;
    box-sizing: border-box;
    border: solid 1px $grey2;
    color: $text-color;
  }
  .row-container,
  th {
    font-size: 14px;
    font-weight: 600;

    .tag-column {
      max-width: 350px;
    }
  }
}

// Switch (Toggler) styles overriding

.switch {
  width: 36px;
  height: $toggler-height;
  .switch-toggle {
    width: $toggler-height;
    height: $toggler-height;
    background: $grey4;
    border: none;
  }
  &.on {
    background: $white-color;
    border-color: $yellow;
    .switch-toggle {
      left: $toggler-height;
      background: $yellow;
    }
  }
}
