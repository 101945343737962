@import 'src/style/variables';
@import 'src/style/font-family';

.movie-fetch-details {
  border-bottom: $input-border;
  box-sizing: border-box;
  display: flex;
  padding: 16px 0;

  .cover-pic {
    box-sizing: border-box;
    width: 260px;
    height: 160px;
  }

  .content {
    margin: 0 40px;

    .categories {
      color: $grey4;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 12px;
    }

    .name {
      @include set-typeface('Libre-Medium');
      color: $text-color;
      font-size: 16px;
      margin: 12px 0;
    }

    .details {
      display: flex;
    }

    .ratings {
      margin-left: 20px;
    }

    .review-btn {
      margin-top: 12px;
    }

    p {
      color: $text-color;
      font-size: 14px;
      line-height: 16px;
      margin: 6px 0;
    }
  }
}
