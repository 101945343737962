@import 'src/style/variables';
@import 'src/style/font-family';

.loading-fetch-card {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 172px;

  &.small {
    height: 67px;

    .fetch-card-loader {
      width: 20px;
      height: 20px;
    }
  }
  .fetch-card-loader {
    transform: translate(-50%, -50%);
    border: 10px solid $grey2;
    border-top: 10px solid $yellow;
    border-radius: 50%;
    box-sizing: content-box;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    z-index: 100;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
