$price-field-margin-right: 32px;

.amount-people-container {
  display: flex;
  flex-direction: row;
  .hours-range-label {
    color: #a8a4a0;
    display: inline-block;
    font-size: 13px;
    margin-bottom: 12px;
  }
  .amount-container,
  .for-container {
    width: calc(50% - #{$price-field-margin-right/2});
    margin-right: initial;
  }
  .amount-container {
    margin-right: $price-field-margin-right;
  }
}
