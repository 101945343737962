// Styles that will override third part library style.
@import './variables';

.input-bar {
  &::placeholder {
    color: $grey;
  }
}

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, auto));
  grid-column-gap: 16px;
  grid-row-gap: 30px;
  margin-top: 24px;
  img {
    object-fit: cover;
  }
}
.card-container {
  &.image-inspector-container {
    grid-template-columns: repeat(auto-fill, minmax(390px, auto));
  }
}

.place-fetch-details img {
  object-fit: cover;
}

.movie-fetch-details img {
  object-fit: cover;
}

.amount-people-container {
  .amount-container,
  .for-container {
    .Dropdown-control {
      height: 43px;
    }
  }
}

.Dropdown-control .is-selected,
.rc-time-picker-input {
  color: black !important;
  opacity: 1 !important;
}

.Dropdown-disabled {
  background-color: $grey9;
}

.rc-time-picker-input {
  text-transform: uppercase;
}

.DateRangePicker {
  border: 1px solid $grey1;
  border-radius: 4px;
  width: 100%;
  margin: 18px;

  .DateRangePicker__PaginationArrow {
    margin: 8px;
  }

  .DateRangePicker__Month {
    margin: 0;
    width: 100%;
  }

  .DateRangePicker__MonthDates {
    border-top: 1px solid $grey1;
    padding: 8px;
  }

  .DateRangePicker__Date,
  .DateRangePicker__MonthDates,
  .DateRangePicker__WeekdayHeading {
    border: none;
  }

  .DateRangePicker__WeekdayHeading,
  .DateRangePicker__MonthHeaderLabel {
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
  }

  .DateRangePicker__WeekdayHeading {
    abbr {
      &[title] {
        color: $grey4;
      }
    }
  }

  .DateRangePicker__Date--weekend {
    background: none;
  }

  .DateRangePicker__MonthDates {
    border-top: 1px solid $grey1;
  }

  .DateRangePicker__MonthHeader {
    padding: 8px;
  }

  .DateRangePicker__PaginationArrow--next {
    right: 0;
    border-radius: 50%;
  }

  .DateRangePicker__PaginationArrowIcon--next {
    top: 12px;
    left: 10px;
    width: 8px;
    height: 8px;
    transform: rotate(-45deg);
    border: 1px solid black;
    border-left: none;
    border-top: none;
  }

  .DateRangePicker__PaginationArrow--previous {
    left: 0px;
    border-radius: 50%;
  }

  .DateRangePicker__PaginationArrowIcon--previous {
    top: 12px;
    left: 14px;
    width: 8px;
    height: 8px;
    transform: rotate(135deg);
    border: 1px solid black;
    border-left: none;
    border-top: none;
  }

  .DateRangePicker__Date {
    line-height: 20px;

    &.DateRangePicker__Date--today {
      font-weight: bold;
    }
  }

  .DateRangePicker__CalendarHighlight--single {
    border-radius: 50%;
    border-color: $date-picker-selected-date;
  }

  .DateRangePicker__CalendarSelection--single {
    border-radius: 50%;
    border-color: $date-picker-selected-date;
    background: $date-picker-selected-date;
  }
}

.datepicker-drop-down {
  .dropdown-menu {
    .svg-wrapper {
      &:hover,
      &:focus {
        img {
          filter: contrast(0.3);
        }
      }
    }
  }

  &.is-open {
    .svg-wrapper {
      img {
        filter: contrast(0.5) brightness(0.5);
      }
    }
  }
}
// This is to hide it from sonar code smell
.cob-react-multiselect {
  [class$='-multiValue'] {
    display: none;
  }

  [class$='-placeholder'] {
    color: $grey;
  }
}
// This is to hide it from sonar code smell
.cob-react-select {
  [class$='-placeholder'] {
    color: $grey;
  }
}
// Override dropdown-root margin for new category form
.category-form {
  .Dropdown-root {
    margin-bottom: 1.5rem;
  }
}
