@import 'src/style/variables';
@import 'src/style/font-family';

.delete-modal {
  background-color: $white-color;
  border-radius: 8px;
  left: 50%;
  padding: 32px 24px;
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  z-index: $z-index-modal;

  .modal-header {
    @include set-typeface('Libre-Medium');
    border-bottom: $delete-modal-border;
    box-sizing: border-box;
    color: $text-color;
    padding-bottom: 24px;
  }

  .modal-content {
    color: $text-color;
    font-size: 14px;
    margin: 24px 0;
  }

  .btn-container {
    display: flex;
    justify-content: space-between;

    .btn {
      @include set-typeface('Libre-Medium');
    }
  }

  span {
    display: inline-block;
  }
}
