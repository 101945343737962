@import '../../../style/variables';

.event-date-selector-modal {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-width: 60%;
  min-width: 625px;
  height: 80vh;
  max-height: 80vh;
  padding: 0;

  .modal-header {
    flex-shrink: 0;
    padding: 24px 24px 20px 24px;
    margin-bottom: 0px;
    border-bottom: 1px solid #efefef;

    .modal-title {
      font-size: 22px;
    }

    .modal-close {
      padding-right: 2px;
    }
  }

  .event-date-selector-modal__selection-content {
    display: flex;
    min-height: 2em;
    flex-grow: 1;

    .event-date-selector-modal__selection-content--date-picker {
      min-width: 50%;
      border-right: 1px solid #efefef;

      .modal-content {
        height: 100%;
        box-sizing: border-box;
        transition: all 0.3s ease-out;
        margin: 0;
        padding: 0;
        overflow: hidden;
        overflow-y: auto; // overlay depreciated in firefox
        padding: 15px 35px 0px 35px;

        .modal-content__date-selector {
          display: flex;
          align-items: center;
          justify-content: center;

          .datepicker--calendar {
            .DayPicker-Wrapper {
              margin-bottom: 0px;
            }
            .DayPicker-Month {
              margin-top: 0px;
              font-size: 20px;
            }

            .DayPicker-NavButton--prev {
              top: 0px;
            }

            .DayPicker-NavButton--next {
              top: 0px;
            }

            .DayPicker-Caption__options {
              border: none;
              font-size: inherit;
            }

            .DayPicker-Footer {
              padding-top: 1.5em;
            }
          }

          .datepicker--options {
            display: flex;
            flex-direction: column;

            .switch-option-dateRange {
              vertical-align: sub;

              .switch {
                margin-left: 10px;
              }
            }
          }
        }

        .modal-content__time-selector {
          .timer-separator {
            color: #a8a4a0;
            margin: 0px 15px;
          }

          .recurrence-selector {
            margin-bottom: 30px;
          }
        }

        .DateRangePicker {
          margin: 0;
        }

        .DateRangePicker__MonthDates {
          border-spacing: 6px 6px;
        }
      }
    }

    .event-date-selector-modal__selection-content-dates-list {
      min-width: 50%;

      h2 {
        margin: 30px 0px 25px 15px;
        text-align: left;
        font-size: medium;
        font-weight: 500;
      }

      .modal-content {
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
      }

      .event-dates-summary--wrapper {
        display: flex;
        flex-direction: column;
        margin: 12px;

        background-color: $date-summary-background;
        border-radius: 4px;
        cursor: pointer;

        font-size: 16px;
        font-weight: 500;

        .date-time-row {
          display: flex;
          // justify-content: space-between;
          // align-items: center;
          margin: 16px;
        }

        .date-time {
          color: $text-color;
          flex: 1;
        }

        .separator {
          margin: 0 24px;
        }

        .delete-summary {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;

          img {
            width: 16px;
            height: 16px;
          }
        }
      }

      // specific for the modal and not the events form
      .modal-date-summary {
        display: flex;
        margin: 16px;
        margin-top: 0;
        padding-top: 0px;
        border-top: 1px solid $separator-line;

        .recurrence-options-summary {
          display: flex;
          align-items: center;
          justify-content: space-evenly;

          .weekdays-info {
            margin-right: 0px;

            .week-day {
              width: 25px;
              height: 25px;

              &:not(.selected) {
                background: transparent;
                border-color: $grey;
                color: $grey;
              }
            }
          }

          label {
            font-size: 13px;
            font-weight: 500;
            color: $grey4;
          }

          .info-value {
            font-size: 14px;
            margin-top: 16px;
          }
        }
      }
    }
  }

  .modal-footer {
    // display: flex;
    flex-shrink: 0;
    margin-top: 0px;
  }

  &.modal {
    // position: relative;
    // margin: 20px 0;
    // display: inline-block;
    transform: initial;
    left: initial;
    top: initial;
    &.modal-show {
      // overflow: visible;
    }
  }

  .btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    padding: 24px 0px;
    text-align: center;
    height: 88px;
    z-index: initial;
    box-sizing: border-box;
    box-shadow: 0 -3px 19px 0 rgba(0, 0, 0, 0.05);
    border-radius: 0 0 16px 16px;

    button {
      min-width: 125px;
      font-size: 14px;
      height: 40px;
    }
  }

  .cob-checkbox {
    margin: 24px 0;
    .label-text {
      font-size: 13px;
      color: $grey4;
    }
  }

  .modal-title-bar {
    display: flex;

    .modal-previous {
      margin-right: 20px;
      box-sizing: border-box;
      transform: rotate(90deg) scale(1.2);
      cursor: pointer;
    }
  }
}

.rc-time-picker-panel-inner {
  top: 3px;
}

.popup-foreground-outer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  z-index: 1002;
  top: 0;
  left: 0;
}
.popup-foreground-table {
  width: 100%;
  // display: table;
  // position: relative;
  height: 100%;
}
.popup-foreground-wrapper {
  // float: initial;
  // display: table-cell;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  vertical-align: middle;
  text-align: center;
}
