@import 'src/style/font-family';
@import 'src/style/variables';

.card-info {
  box-sizing: border-box;
  .card-title {
    @include set-typeface('PT-bold');
    font-size: 16px;
    // margin-bottom: 10px;
    word-break: break-word;
    padding: 20px 0 10px 0;
  }
  .card-subtitle {
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}
