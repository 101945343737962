@import 'src/style/variables';
@import 'src/style/font-family';

.location-count {
  @include set-typeface('Libre-bold');
  box-sizing: border-box;
  width: 40px;
  height: 24px;
  background-color: $translucent-teal;
  text-align: center;
  color: $white-color;
  font-size: 13px;
  padding: 4px 0;
  img {
    margin-right: 6px;
    vertical-align: top;
  }
}
