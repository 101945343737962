@import 'src/style/variables';
@import 'src/style/font-family';

.card-footer {
  align-items: center;
  background-color: $white-color;
  display: flex;
  justify-content: space-between;
  padding: 22px 12px;
  border-top: 1px solid $grey1;

  .card-footer-content {
    color: $text-color;
    font-size: 12px;

    li {
      margin-bottom: 10px;

      .card-footer-label {
        @include set-typeface('Libre-Medium');
        color: $grey;
      }
    }

    li:last-child {
      margin-bottom: 0px;
    }
  }
}
