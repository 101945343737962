@import 'src/style/font-family';
@import 'src/style/variables';

.detailed-description__container {
  button {
    padding: 0;
    margin-bottom: 10px;
    border: none;
    background: none;
  }

  .detailed-description__header {
    font-family: $libre-franklin;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $text-color;

    margin-bottom: 25px;
  }

  .detailed-description__input-wrapper {
    display: flex;
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e7e7e7;

    label {
      display: inline-block;
      margin-bottom: 10px;

      font-family: Libre Franklin;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 15px;
      color: $grey;
    }

    .detailed-description__input-column {
      margin-right: 15px;
    }

    .detailed-description__input-column {
      width: 35%;
    }

    .detailed-description__text-area-column {
      width: 60%;
    }

    .detailed-description__input-delete {
      text-align: center;
      margin-top: 25px;
      width: 5%;
    }
  }

  .detailed-description__add-section {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Libre Franklin;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    color: $yellow;
  }
}
