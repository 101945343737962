@import 'src/style/variables';

.preview-wrapper {
  background-color: $white-color;
  border-radius: 24px;
  box-sizing: border-box;
  padding: 40px;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 455px;
  z-index: $z-index-modal;

  .tab-content {
    border-radius: 16px;
    box-shadow: $preview-modal-box-shadow;
    max-height: 45vh;
    overflow-x: hidden;
  }
}
