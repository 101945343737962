@import 'src/style/variables';
@import 'src/style/font-family';

.preview-modal {
  background-color: $white-color;

  .overflow-container {
    background-color: $white-color;
    max-height: 45vh;
    overflow-y: auto;
  }

  .image-carousel {
    position: relative;
    height: 264px;
    width: 375px;

    img {
      background-color: $grey3;
      box-sizing: border-box;
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }
  .dots {
    bottom: 16px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);

    .dot {
      box-sizing: border-box;
      border: 1px solid $white-color;
      border-radius: 50%;
      display: inline-block;
      height: 10px;
      margin: 0 4.6px;
      width: 10px;
    }

    .dot:first-child {
      background-color: $white-color;
    }
  }

  .close-icon {
    cursor: pointer;
    background-color: $white-color;
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
    box-sizing: content-box;
    height: 20px;
    padding: 12px;
    position: absolute;
    right: 5px;
    top: 5px;
    transform: translate(50%, -50%);
    width: 20px;
    z-index: $z-index-modal-close;
  }

  .modal-detail {
    margin: 30px 16px 40px;

    .split-screen {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
    }

    .contact {
      align-items: center;
      display: flex;
      margin-top: 12px;
    }

    .phone {
      margin-left: 4px;
    }

    .title {
      @include set-typeface('PT-Bold');
      color: $text-color;
      font-size: 18px;
      line-height: 24px;
      text-decoration: underline $yellow;
    }

    .details {
      @include set-typeface('Libre-Medium');
      color: $grey4;
      font-size: 14px;
      line-height: 17px;
      margin: 16px 0;
    }

    .pricing {
      display: flex;
      align-items: center;
      margin: 8px 0;
    }

    .separator {
      margin: auto 6px;
      position: relative;
      transform: translateY(-25%);
    }

    .read-more-btn {
      color: $yellow;
      cursor: pointer;
      display: inline-block;
      margin: 0 4px;
    }

    .desc {
      @include set-typeface('Libre-Medium');
      color: $text-color;
      font-size: 14px;
      margin: 8px 0;
      line-height: 21px;
    }
  }

  .website {
    @include set-typeface('Libre-Medium');
    color: $text-color;
    font-size: 14px;
    line-height: 17px;
    text-decoration: underline;
  }

  .btn-container {
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    line-height: 17px;
    margin: 32px 0;

    .round {
      border-radius: 25px;
      box-shadow: $round-btn-box-shadow;
    }

    .white-bg {
      @include set-typeface('Libre-Medium');
      color: $text-color;
      background-color: $white-color;
    }
  }

  .map-placeholder {
    width: 100%;
    height: 155px;
    margin-bottom: 16px;
  }

  .address {
    color: $grey4;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 32px;
    text-align: center;
  }
  .address.event {
    text-align: left;
    margin-bottom: 16px;
  }
}
