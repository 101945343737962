@import 'src/style/variables';
@import 'src/style/font-family';

$model-header-height: 70px;
$model-btn-container-height: 100px;

.edit-modal-popup {
  background-color: $white-color;
  border-radius: 8px;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  z-index: $z-index-modal;
  width: 570px;
  height: 36rem;
  max-height: 90%;

  .btn-container {
    box-shadow: 0 -3px 19px 0 rgba(0, 0, 0, 0.05);
    padding: 24px 0;
  }

  .edit-modal-container {
    height: calc(100% - (#{$model-header-height + $model-btn-container-height}));
    overflow-y: scroll;
    padding: 0 24px;

    .edit-days-and-hours-container {
      margin-top: 16px;

      .edit-days-container {
        display: flex;
        align-items: center;

        ul {
          margin-top: 0px;
        }
      }

      edit-hours-modal: {
        border-bottom: none;
      }
    }

    .btn-container {
      box-shadow: none;
      border-bottom: 1px solid #eeedec;
    }

    .timer-separator {
      margin: 0px 10px;
    }

    .timer {
      align-items: center;
      display: flex;
      margin-bottom: 24px;

      .timer-separator {
        @include set-typeface('Libre-Medium');
        color: $text-color;
        margin: 0 38px;
      }

      > div {
        width: 100%;

        .weekday-timings-container {
          display: flex;
          margin: 5px 0;
          font-size: 16px;
          line-height: 30px;

          .weekday {
            text-transform: capitalize;
            width: 30%;
            font-weight: bold;
          }

          .weekday-timings {
            display: block;

            .time-seperator {
              margin: 0 42px 0 0;
            }
          }
        }
      }
    }
  }

  // .timer-separator {
  //   padding: 0 24px;
  // }
  .time-selector {
    padding-bottom: 12px;
  }
  .modal-header {
    @include set-typeface('Libre-Medium');
    border-bottom: $delete-modal-border;
    box-sizing: border-box;
    color: $text-color;
    padding: 26px 0 16px 24px;
    font-size: 24px;
    font-weight: 500;
    height: $model-header-height;
  }

  .modal-content {
    color: $text-color;
    font-size: 14px;
    margin: 24px 0;
  }

  .btn-container {
    display: flex;
    justify-content: center;
    height: $model-btn-container-height;
    box-sizing: border-box;

    .btn {
      @include set-typeface('Libre-Medium');
      width: 140px;
    }
  }
}
