// Font family importing here
@import url('https://fonts.googleapis.com/css?family=Libre+Franklin:400,500,600,700|PT+Serif:700&display=swap');

/* 
  @description this function is used to determine font-family without browser default, when font initials is passed to it. If not matched this will return default of font("libre Franklin").

  @params {String} this will be accepting list of value which are present in $font-family map

  @returns {Number} font-family value for a given font
*/
@function getFontFamily($font) {
  $default: 'Libre Franklin';

  $font-family: (
    'Libre': 'Libre Franklin',
    'PT': 'PT Serif',
  );
  @return map-get($map: $font-family, $key: $font) or $default;
}

/* 
  @description this function is used to determine font-weight when type of font weight passed to it. If not matched this will return default of 400 font-weight

  @params {String} this will be accepting list of value which are present in $weights map

  @returns {Number} font-weight value for a given weight
*/
@function getFontWeight($weight) {
  $default: 400;

  $weights: (
    'regular': 400,
    'medium': 500,
    'semi-bold': 600,
    'bold': 700,
  );

  @return map-get($map: $weights, $key: to-lower-case($weight)) or $default;
}

// Font weight setting up
@mixin set-typeface($typeface) {
  // Split typeface by '-' to get font-family and font-weight.
  $splitter: str-index($typeface, '-');

  // Left side to splitter we have font-family, pass it to getFontFamily function and also append default browser font.
  font-family: getFontFamily(str-slice($typeface, 1, $splitter - 1)), serif;

  // Right side to splitter we have font-weight, pass it to getFontWeight function to return font weight.
  font-weight: getFontWeight(str-slice($typeface, $splitter + 1));
}
