@import 'src/style/font-family';
@import 'src/style/variables';

.edit-cities-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .edit-cities-modal {
    width: 100%;
    max-width: 850px;

    .no-neighborhoods-found {
      align-items: center;
      color: #353029;
      display: flex;
      justify-content: center;
      min-height: inherit;
      flex-direction: column;

      .message {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 1rem;
      }
      .sub-message {
        font-size: 16px;
      }
    }

    h1 {
      @include set-typeface('Libre-Medium');
      color: $text-color;
      font-size: 33px;
      line-height: 40px;
      margin-bottom: 35px;
    }

    .city-form {
      margin-bottom: 50px;

      .form-input-categories-label {
        display: flex;
      }
    }

    .switch-container {
      display: flex;
      align-items: center;

      label {
        cursor: pointer;
        padding-top: 2px;
        padding-left: 1rem;
        color: #353029;
      }
    }

    .neighborhoods-list-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      h2 {
        text-align: left;
        font-weight: bold;
        font-size: 16px;
        margin: 0;
      }

      .advanced-options-checkbox-container {
        display: flex;
        flex-flow: wrap;

        .advanced-options-checkbox {
          display: flex;
          align-items: center;
          justify-content: center;

          margin-right: 15px;
          margin-bottom: 10px;

          .advanced-options-checkbox-text {
            margin: 0px 5px 0px 5px;
          }
        }
      }
    }

    .modal-actions {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;

      .action-btn {
        width: 140px;
        &.btn-inverse {
          margin-right: 1.5rem;
        }
      }
    }
  }
}
