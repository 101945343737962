@import 'src/style/variables';

.modal {
  position: fixed;
  background-color: $white-color;
  width: $container-width;
  border-radius: 16px;
  padding: 40px;
  left: 50%;
  top: 50%;
  box-sizing: border-box;
  transform: translate(-50%, -100%);
  opacity: 0;
  transition: all .3s ease-out;
  z-index: -1;
  &.modal-show {
    transform: translate(-50%, -50%);
    opacity: 1;
    z-index: $z-index-modal;
    overflow: hidden;
  }

  .modal-innerWrapper {
    position: relative;
    width: 100%;
    float: left;
  }

  .modal-header {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    line-height: 29px;
    box-sizing: border-box;
    position: relative;

    .modal-title {
      font-weight: 500;
      font-size: 24px;
      color: $text-color;
    }

    .modal-close {
      cursor: pointer;
      width: 24px;
      height: 25px;
    }
  }

  .modal-footer {
    position: relative;
    // bottom: 2rem;
    margin-top: 20px;
  }
}
