@import '../../style/variables';

.event-date-selector {
  label {
    &.heading {
      color: $grey;
      font-size: 13px;
      font-weight: 500;
      display: block;
    }
  }

  .add-date {
    color: $yellow;
    margin-top: 24px;
    display: inline-block;

    text-decoration: underline;
    cursor: pointer;
  }

  .date-summary {
    margin-top: 24px;
  }
}
