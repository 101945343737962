@import 'src/style/variables';
@import 'src/style/font-family';

.preview-card {
  background-color: $white-color;
  border-radius: 8px;
  box-shadow: $preview-card-box-shadow;
  display: flex;
  margin: 32px 16px;

  .image-section {
    box-sizing: border-box;
    margin-right: 16px;
    width: 100px;

    img {
      box-sizing: border-box;
      border-radius: 8px 0 0 8px;
      display: inline-block;
      width: 100px;
      height: 100%;
    }
  }

  .content {
    color: $text-color;
    font-size: 14px;
    line-height: 17px;
    align-self: center;

    .name {
      @include set-typeface('Libre-Bold');
    }

    .place-details,
    .timings {
      display: flex;
      align-items: center;
    }

    .name,
    .category,
    .place-details,
    .neighborhood,
    .timings {
      margin: 4px 0;
    }

    .dot {
      position: relative;
      margin: 0 4px;
      transform: translateY(-25%);
    }
  }
}
