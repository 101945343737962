@import 'src/style/variables';

.button-search-bar {
  width: 75%;
  display: flex;
  align-items: center;

  .search-bar-container {
    width: 50%;
    margin-right: 5px;
  }
}
