.warning-modal {
  .btn-container {
    display: flex;
    justify-content: center;

    .btn {
      margin-right: 24px;
      min-width: 120px;
    }
  }
}
