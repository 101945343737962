@import 'src/style/variables';

.label {
  display: inline-block;
  font-weight: 600;
  font-size: 10px;
  padding: 0px 5px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background-color: white;

  &.published {
    color: $green;
  }

  &.draft {
    color: $yellow;
  }

  &.expired,
  &.flagged {
    color: $red;
  }

  &.activity,
  &.curated,
  &.event,
  &.place,
  &.movie,
  &.recipe {
    color: $blue;
  }
}
