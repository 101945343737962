@import 'style/variables';

.third-party-search-input-container {
  .search-bar-container {
    width: 100%;
  }

  .third-party-search-options-dropdown {
    position: absolute;
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
    background-color: #ffffff;
    border: 1px solid #d0d0d0;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

    z-index: $z-index-modal-dropdown;

    .third-party-search-option-wrapper {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e7e7e7;

      // movie only
      .third-party-search-poster {
        object-fit: cover;
        height: 45px;
        width: 35px;
        margin-left: 15px;
      }

      .third-party-search-option {
        font-family: $libre-franklin;
        margin: 15px;
        cursor: pointer;

        h3 {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;

          margin-bottom: 5px;
        }

        span {
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;

          color: #9e9c9a;
          margin: 0 5px 0 0;
        }
      }
    }
  }
}
