@import '../../../style/variables';

.dropdown-panel {
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  box-sizing: border-box;
  background: white;
  width: 340px;
  position: absolute;
  right: 0;
  z-index: 1;
  margin-top: 8px;

  .datepicker-panel--header {
    padding: 16px 12px;
    border-bottom: 1px solid rgb(204, 204, 204);
  }

  .datepicker--calendar {
    display: flex;
    justify-content: center;
  }

  .datepicker--from-date {
    margin: 8px 18px;
    display: block;
    font-size: 10px;
    color: $grey;

    span {
      color: $black-color;
      margin-left: 3px;
    }

    &.show {
      visibility: visible;
    }

    &.hide {
      visibility: hidden;
    }
  }

  .datepicker--button-panel {
    display: flex;
    margin: 24px 18px;
    justify-content: space-between;

    &.next {
      justify-content: flex-end;
    }

    .btn {
      flex: 1;
      height: 32px;
      padding: 0;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: normal;
    }

    .back {
      margin-right: 16px;
    }

    .next {
      flex: .5;
      align-self: flex-end;
      margin-left: 16px;
    }
  }
}
