@import 'src/style/variables';

.btn-dropdown {
  display: inline-block;

  .btn {
    font: {
      weight: 600;
      size: 14px;
    }

    border: none;
    color: $white-color;
    background-color: $yellow;
    line-height: 24px;
    padding: 0.6rem 1.4rem;
    letter-spacing: 1.39px;
    outline-color: $yellow;

    cursor: pointer;
    &.btn-large {
      width: 330px;
    }

    &.btn-sm {
      padding: 0.4rem 1.4rem;
    }

    &.btn-inverse {
      border: 1px solid $yellow;
      background: $white-color;
      color: $yellow;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
      outline: none;
      cursor: no-drop;
    }
  }

  img {
    margin-bottom: 2px;
  }

  .btn-dropdown-menu {
    position: absolute;
    background: #ffffff;
    border: 1px solid #e7e7e7;
    box-sizing: border-box;
    border-radius: 2px;
    width: 195px;
    margin-top: -10px;
    margin-left: 10px;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
    z-index: 1;

    .btn-dropdown-menu-options {
      // position: absolute;
      height: 40px;
      width: 193px;

      margin: 0px;
      padding: 0px;

      border: none;
      background: #ffffff;

      font-family: 'Libre Franklin', 'serif';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;

      text-align: left;
      padding-left: 15px;
    }

    .btn-dropdown-menu-options:hover {
      background: #fafafa;
    }
  }
}
