.curated-card {
  .page-content {
    box-sizing: border-box;
    margin: 32px 44px;
  }
  .curated-widget {
    position: fixed;
    bottom: 0;
    width: calc(100vw - 222px);
  }
}
