@import 'src/style/font-family';
@import 'src/style/variables';

.form-input {
  @include set-typeface('Libre-Regular');
  text-align: left;
  label {
    color: $grey;
    display: inline-block;
    font-size: 13px;
    margin-bottom: 12px;
  }
  .error {
    color: $error-color;
    margin: 10px 0 25px 0;
    font-size: 14px;
  }
}
