@import 'src/style/font-family';
@import 'src/style/variables';

.vertical-form {
  .form-content {
    margin-top: 40px;
  }

  .form {
    width: 100%;
  }

  .actions button {
    margin: 10px;
  }

  .input-content {
    margin: 15px 0 0 0;
  }

  label {
    color: $grey;
    font-size: 13px;
  }

  .file-uploader {
    margin: 40px 0;
  }

  .form-field-wrapper {
    width: 50%;
  }

  .overlay-wrapper {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
  }
}
