.hours-range {
  .hours-range-label {
    width: 100%;
    text-align: left;
  }

  .timer {
    .rc-time-picker {
      width: 100%;
    }
    .time-picker--wrapper {
      display: flex;
      flex-grow: 1;
      min-width: 120px;
    }

    .timer-separator {
      margin: 0 24px;
    }
  }
}
