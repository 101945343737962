@import 'src/style/variables';
@import 'src/style/font-family';

.toast-container .Toastify__toast {
  position: fixed;
  box-sizing: border-box;
  text-align: left;
  color: $white-color;
  padding: 8px 13px;
  width: 330px;
  border-radius: 5px;
  @include set-typeface('Libre-medium');
}

.toast-container.Toastify__toast-container--top-right {
  top: 1em;
  right: 1.5em;
}

.toast-container .Toastify__toast--success {
  background: $green;
}
.toast-container .Toastify__toast--error {
  background-color: $red;
}
