// Variable value
$container-width: 85%;
$nav-bar-height: 72px;

// Color codes
$light-text-color: #4a4a4a;
$black-color: #000000;
$grey: #a8a4a0;
$grey1: #cccccc;
$grey2: #d0d0d0;
$grey3: #d8cdcd;
$grey4: #9a9794;
$grey5: #9a979414; // Added for table children row background
$grey6: #b3b3b3;
$grey7: #999999;
$grey8: #716c67;
$grey9: #f0f0f0;
$green: #4bba5c;
$red: #e03616;
$nav: #f9f3e9;
$nav-active: #f3d7a7;
$text-color: #353029;
$input-color: #a8a4a0;
$white-color: #ffffff;
$white-red-color: #fffbfb;
$yellow: #f7bc25;
$error-color: #df2f2f;
$blue: #007bff;
$translucent-teal: rgba(64, 202, 198, 0.8);

$backdrop-bg: rgba(0, 0, 0, 0.2);

// Border values
$input-border: 1px solid rgba(168, 164, 160, 0.5);
$tab-active-border: 1px solid #f7bc25;
$search-border: 1px solid #d0d0d0;
$delete-modal-border: 1px solid #eeedec;

// Box shadows
$input-box-shadow: 0 2px 5px 0 rgba(42, 57, 80, 0.03);
$navbar-box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.06);
$nav-title-text-shadow: 0 2px 16px 0 #000000;
$side-bar-box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0);
$side-bar-active-box-shadow: inset 0 1px 0 0 #dfe2e5, 0 1px 0 0 #dfe2e5;
$login-card-box-shadow: 0 2px 6px 0 rgba(42, 57, 80, 0.05);
$dropdown-box-shadow: 0 2px 26px 0 rgba(0, 0, 0, 0.08);
$sub-menu-box-shadow: 0 2px 21px 0 rgba(0, 0, 0, 0.13);
$widget-box-shadow: 0 -3px 19px 0 rgba(0, 0, 0, 0.05);
$preview-card-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.25);
$round-btn-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
$preview-modal-box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.12),
  0 2px 82px 0 rgba(0, 0, 0, 0.05);

// z-index value
$z-index-navbar: 1000;
$z-index-backdrop: 1001;
$z-index-card-modal: 1002;
$z-index-modal: 1003;
$z-index-modal-close: 1004;
$z-index-modal-dropdown: 1005;

// font family type-faces
$libre-regular: 'Libre-Regular';
$libre-franklin: 'Libre Franklin';
$date-picker-selected-date: rgb(62, 202, 198);
$separator-line: #eeedec;
$date-summary-background: #f9f3e9ad;
