@import 'src/style/font-family';
@import 'src/style/variables';

.search-bar-container {
  position: relative;
  width: 280px;

  .search-icon {
    left: 8px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .search-bar {
    font-family: "libre franklin", "serif";
    border: $search-border;
    box-sizing: border-box;
    color: $text-color;
    font-size: 14px;
    height: 40px;
    outline: none;
    padding: 0 40px;
    width: 100%;
    border-radius: 4px;

    &:focus {
      border: $tab-active-border;
    }

    &::placeholder{
      font-family: "libre franklin", "serif";
      font-size: 14px; font-weight: 400; color: #a8a4a0; opacity: 1;
    }
  }

  .clear-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
