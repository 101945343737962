@import 'src/style/variables';
@import 'src/style/font-family';

.preview-tab {
  margin: 40px 0;
  display: flex;

  .tab {
    @include set-typeface('Libra-Medium');
    color: $grey4;
    cursor: pointer;
    margin-right: 40px;
    padding-bottom: 7.5px;

    &.active {
      color: $yellow;
      border-bottom: 1px solid $yellow;
    }
  }
}
