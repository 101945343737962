@import './../../../style/variables';

.filter-form {
  .new-filter-checkbox {
    color: $grey4;

    &.togglers {
      display: inline-block;
      box-sizing: border-box;
      width: 100%;
      padding: 0 0 1.5rem 0;
    }
  }

  .Dropdown-root {
    &.drop-down {
      margin-bottom: 10px;
    }
    .Dropdown-menu {
      max-height: 120px;
    }
  }

  .form-input-categories-label {
    display: flex;
  }

  .filter-form-dropdown {
    // margin-top: 25px;
  }

  .option,
  .check-box-option {
    display: flex;
    align-items: center;
    outline: none;
    padding: 8px 12px;

    .cob-checkbox {
      height: 18px;
    }

    &.is-selected:not(:hover) {
      background: none;
      color: $black-color;
    }
  }
}

.switch-container {
  display: flex;
  align-items: center;

  label {
    cursor: pointer;
    padding-top: 2px;
    padding-left: 1rem;
    color: #353029;
  }
}

.advanced-options-container {
  margin-bottom: 50px;

  .advanced-options-header-label {
    text-align: left;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .advanced-options-checkbox-container {
    display: flex;
    flex-flow: wrap;

    .advanced-options-checkbox {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-right: 15px;
      margin-bottom: 10px;

      .advanced-options-checkbox-text {
        margin: 0px 5px 0px 5px;
      }
    }
  }
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  .action-btn {
    width: 140px;
    &.btn-inverse {
      margin-right: 1.5rem;
    }
  }
}
