@import 'src/style/variables';

.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: $z-index-backdrop;
  left: 0;
  top: 0;
  background-color: $backdrop-bg;
}
