@import 'style/variables';

.checkbox-container {
  display: flex;
  align-items: center;
  color: $text-color;

  .cob-checkbox {
    margin-right: 8px;
  }

  .checkbox-text {
    font-family: $libre-franklin;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    white-space: nowrap;
  }
}
