@import '../../style/variables';
 
.image-inspector-container{
  .card{
    width: 390px;
    box-sizing: content-box !important;
  }
  .external-label{
    position: absolute;
    z-index: 10;
    font-size: 40px;
    background-color: #fff9;
    width: 100%;
    padding: 10px 0;
    text-align: center;
    top: 50%;
    transition: all ease 0.2s;
  }

  .card-header:hover{
    .external-label{
      opacity: 0;
    }
  }

  .card-inner-wrapper{
    border-radius: 5px;
    box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.06);
  }

  .card-content {
    border-radius: initial;
    box-shadow: initial;
  }
}
.card-content {
  box-sizing: border-box;
  cursor: pointer;
  width: 260px;
  min-height: 334px;
  border-radius: 5px;
  box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid transparent;
  text-align: left;

  .card-body {
    padding: 0 12px 15px 12px;
    word-break: break-all;
  }

  &.selected-card {
    box-sizing: border-box;
    border: 2px solid $yellow;
    border-radius: 5px;

    .card-image {
      box-sizing: border-box;
      border-radius: 5px 5px 0 0;
    }
  }
}
