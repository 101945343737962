$price-field-margin-right: 32px;

.pricing-dropdown-wrapper {
  .purchase-amount-container {
    display: flex;
    flex-direction: row;

    .purchase-type-label {
      color: #a8a4a0;
      display: inline-block;
      font-size: 13px;
      margin-bottom: 12px;
    }

    .amount-container,
    .purchase-type-container {
      width: calc(50% - #{$price-field-margin-right/2});
      margin-right: initial;
      margin-right: $price-field-margin-right;

      .purchase-type-container__dropdown {
        margin-bottom: 20px;
      }
    }

    .amount-container {
      margin-right: initial;
      margin-right: 0px;

      .amount-fields {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;

        .amount-container__fields-input {
          height: 45px;
        }

        .purchase-amount__row-delete {
          margin: 5px 5px 5px 5px;
        }
      }
    }
  }

  .purchase-amount__add-link {
    color: #f7bc25;
    text-decoration: underline;
    margin: 24px 0;
    cursor: pointer;
  }
}
