@import 'src/style/font-family';
@import 'src/style/variables';

.drop-down {
  .control {
    border: 1px solid $grey2;
    border-radius: 5px;
    box-sizing: border-box;
    height: 45px;
    padding: 13px 20px;
  }

  .menu {
    background-color: $white-color;
    box-shadow: $dropdown-box-shadow;
    padding: 16px 0;
  }

  .placeholder {
    @include set-typeface('Libre-Regular');
    color: $grey;
    font-size: 15px;
    line-height: 18px;
    opacity: .6;
    font-weight: 400 !important;

    &.is-selected {
      @include set-typeface('Libre-Medium');
      color: $text-color;
    }
  }

  .arrow {
    top: 18px;
  }

  .option {
    @include set-typeface('Libre-Regular');
    color: $text-color;
    font-size: 14px;
    line-height: 17px;
    padding: 8px 24px;

    &:hover {
      background-color: $white-color;
      color: $yellow;
    }
  }
}
