@import 'src/style/variables';
@import 'src/style/font-family';

.filter-section {
  display: flex;
  flex-direction: column;
  margin: 25px 0;

  &.single-row-filters {
    flex-direction: row;
    align-items: end;
    margin: 0 0 10px 0;

    .filter-bar {
      flex: 1;
      flex-wrap: wrap;
    }
  }

  .filter-bar {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 12px;

    .label-text {
      @include set-typeface('Libre-Medium');
      color: $grey;
      font-size: 14px;
    }

    .filter-icon {
      height: 18px;
      margin-right: 16px;
    }

    .filter-dropdown {
      box-sizing: border-box;
      flex: 1;
      min-width: 150px;
      max-width: 280px;
      margin-right: 16px;
    }
  }

  .filter-dropdown {
    &:last-child {
      margin-right: 8px;
    }
  }
  .clear-filter-btn {
    @include set-typeface('Libre-regular');
    color: $text-color;
    background: transparent;
    outline: none;
    padding: 0;
    margin-left: 10px;
    font-size: 14px;
    letter-spacing: 0;
    text-decoration: underline;
  }

  .date-range-filter {
    .display-text {
      color: $grey;
    }
    .display-range {
      color: $text-color;
      font-size: 14px;
    }
  }
}

.search-bar-section {
  display: flex;
  justify-content: space-between;
  margin-right: 8px;
}
