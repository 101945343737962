@import 'src/style/font-family';
@import 'src/style/variables';

.navbar-dropdown-wrapper {
  // width: 100%;

  .navbar-dropdown-header {
    @include set-typeface('Libre-semi-bold');

    width: 100%;
    border: none;
    padding: 24px 0 24px 40px;
    text-align: inherit;
    font-size: 14px;
    color: #4a4a4a;
    background-color: inherit;
    cursor: default;
    outline: none;

    .navbar-dropdown-header__title {
      cursor: pointer;

      img {
        margin-left: 10px;
        margin-bottom: 2px;
      }
    }
  }

  .navbar-dropdown-list {
    display: flex;
    flex-direction: column;

    .navbar-dropdown-list__item {
      @include set-typeface('Libre-semi-bold');
      font-size: 13px;
      color: $grey;
      cursor: pointer;
      text-align: inherit;
      border: none;
      outline: none;
      background-color: inherit;
      padding: 10px 0 10px 40px;

      a {
        padding: 0px;
      }

      .active {
        @include set-typeface('Libre-bold');
        color: $yellow;
        border: none;
      }
    }

    .navbar-dropdown-list__item:hover {
      color: $yellow;
    }
  }
}
