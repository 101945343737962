@import 'src/style/font-family';
@import 'src/style/variables';

// @import '../DropDown/DropDown.scss';

.option,
.check-box-option {
  display: flex;
  align-items: center;
  outline: none;
  padding: 8px 12px;

  .cob-checkbox {
    height: 18px;
  }

  &.is-selected:not(:hover) {
    background: none;
    color: $black-color;
  }
}
