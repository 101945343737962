@import 'src/style/font-family';
@import 'src/style/variables';

.checkbox-row-container {
  .checkbox-row__header {
    font-family: $libre-franklin;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $text-color;

    margin-bottom: 25px;
  }

  .checkbox-row__contents {
    display: flex;
    flex-wrap: wrap;

    .checkbox-row__option {
      margin-right: 20px;
      margin-bottom: 8px;
    }
  }
}
