@import '../../../style/variables';

.event-dates-summary--wrapper {
  display: flex;
  flex-direction: column;

  background-color: $date-summary-background;
  border-radius: 4px;
  cursor: pointer;

  font-size: 16px;
  font-weight: 500;

  .date-time-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px;
  }

  .date-time {
    color: $text-color;
    flex: 1;
  }

  .separator {
    margin: 0 24px;
  }

  .delete-summary {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    img {
      width: 16px;
      height: 16px;
    }
  }
}

.recurrence-options-summary {
  display: flex;
  margin: 16px;
  margin-top: 0;
  padding-top: 16px;
  border-top: 1px solid $separator-line;

  .weekdays-info {
    margin-right: 120px;

    .week-day {
      &:not(.selected) {
        background: transparent;
        border-color: $grey;
        color: $grey;
      }
    }
  }

  label {
    font-size: 13px;
    font-weight: 500;
    color: $grey4;
  }

  .info-value {
    font-size: 14px;
    margin-top: 16px;
  }
}
