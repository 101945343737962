.filters-container {
  margin: 32px 44px;
  position: relative;
}

.filters-modal {
  &.modal-show {
    overflow: hidden;
    transform: initial;
    max-width: 500px;
    display: inline-block;
    top: initial;
    left: initial;
    position: relative;
    margin: 20px 0;
    max-height: 95vh;
    overflow-y: auto;
  }
}
