@import 'src/style/variables';

.card-input {
  position: relative;
  box-sizing: border-box;
  min-width: 260px;
  min-height: 334px;
  opacity: .6;
  border-radius: 8px;
  border: 2px dashed $grey;
  cursor: pointer;

  .add-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
