@import 'src/style/variables';
.rec-event-container {
  margin-top: 16px;
  li {
    display: inline-block;
    margin-right: 8px;
    height: 30px;
    width: 30px;
    box-sizing: border-box;
    border-radius: 50%;
    border: solid 1px $grey;
    background-color: $white-color;
    text-align: center;
    cursor: pointer;
    span {
      display: inline-block;
      margin-top: 8px;
    }
    &.active {
      background-color: $yellow;
      border: solid 1px $yellow;
      color: $white-color;
    }
  }
}
