@import 'src/style/variables';
.vertical-display-container {
  position: relative;
  height: 100%;
  float: left;
  width: 100%;

  .page-content {
    margin: 32px 44px;
    height: calc(100% - 64px);
  }

  .btn-container {
    justify-content: center;

    .btn {
      margin-right: 24px;
    }
  }
}

.vertical-mode-switch{
  display: inline-flex;
  label{
    font-size: 14px; font-family: $libre-franklin;
  }
}
