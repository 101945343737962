@import 'src/style/font-family';
@import 'src/style/variables';

.tabs {
  display: flex;
  cursor: initial;

  .tabs-items {
    @include set-typeface('Libre-semi-bold');
    font-size: 12px;
    color: $grey;
    cursor: default;
    text-transform: uppercase;

    .active {
      color: $yellow;
      border-bottom: $tab-active-border;
    }

    .nav-item {
      margin-right: 50px;
      padding: 7.5px 0;
    }

    .tab-icon {
      margin-right: 5px;
    }
  }

  // Side bar navigation styles
  .side-bar-tabs {
    margin: 0;
    padding: 0;
    color: $light-text-color;

    .nav-link {
      display: flex;
      margin: 0;
      padding: 24px 0 24px 40px;
    }

    .active {
      @include set-typeface('Libre-bold');
      color: $text-color;
      background-color: $nav-active;
      border: none;
      // box-shadow: $side-bar-active-box-shadow;
    }
  }

  &.vertical-tab {
    flex-direction: column;
    background-color: $nav;
    box-shadow: $side-bar-active-box-shadow;

    .tabs-items {
      text-transform: capitalize;
      font-size: 14px;
    }
  }
}
