@import 'src/style/variables';

.card-selector-container {
  box-sizing: border-box;
  max-width: 100%;
  overflow-x: auto;
  padding-bottom: 5px;
  .selected-card-list {
    display: flex;
    justify-content: flex-start;
    .card {
      margin-right: 24px;
    }

    .card-image {
      object-fit: cover;
    }
  }
}
