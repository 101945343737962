@import 'src/style/variables';

.input-bar {
  color: $text-color;
  border: $input-border;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: $input-box-shadow;
  font-size: 15px;
  height: 44px;
  line-height: 18px;
  outline: none;
  padding: 13px 16px;
  width: 100%;
}
