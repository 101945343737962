@import 'src/style/font-family';
@import 'src/style/variables';

.card-list {
  box-sizing: border-box;
  height: calc(100% - 250px);
  overflow: scroll;
  padding-bottom: 20px;
  
  .modal-tabs {
    margin-bottom: 24px;
  }

  .card-list-content-container {
    overflow-y: auto;
    max-height: 70vh;
  }

  .modal-tabs .tab-item {
    @include set-typeface('Libre-medium');
    font-size: 12px;
    color: $grey4;
    margin-right: 50px;
    padding: 7.5px 0;
    text-transform: uppercase;
    display: inline;
    cursor: pointer;

    &.active {
      color: $yellow;
      border-bottom: $tab-active-border;
    }
  }
}

.curated-modal-selected {
  height: 90vh;
  &.modal-show {
    overflow: hidden;
  }

  .card-list-widget {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }
}
