@import 'src/style/variables';

.cob-checkbox {
  .round,
  .square {
    position: relative;
  }
  .round label,
  .square label {
    border: 1px solid $white-color;
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
    height: 18px;
    width: 18px;
    left: 0;
    position: absolute;
    top: 0;

    span {
      left: 2em;
      top: 2px;
      min-width: 100px;
      position: relative;
    }

    &:after {
      border: 2px solid $white-color;
      border-top: none;
      border-right: none;
      box-sizing: border-box;
      content: '';
      height: 5px;
      left: 2px;
      opacity: 0;
      position: absolute;
      top: 4px;
      transform: rotate(-45deg);
      width: 11px;
    }
  }

  .square label {
    border-radius: 0;
    border: 1px solid $grey;
  }

  .round input {
    visibility: hidden;
  }

  .round input:checked + label {
    background-color: $white-color;
  }

  .square input:checked + label {
    background-color: $yellow;
  }

  .round input:checked + label:after {
    opacity: 1;
  }
}
