@import '../../../style/variables';
@import '../../../style/font-family';

.hour-date-range {
  .range-label {
    color: $grey;
    display: inline-block;
    font-size: 13px;
    margin-bottom: 12px;
  }

  .timer {
    align-items: center;
    display: flex;

    .timer-separator {
      @include set-typeface('Libre-Medium');
      color: $text-color;
      margin: 0 38px;
    }
  }
}
