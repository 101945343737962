.rc-time-picker {
  .rc-time-picker-input {
    height: 43px;
    text-transform: none;
  }
}

.rc-time-picker-panel-input-wrap {
  height: 43px;
}
.rc-time-picker-panel-input {
  font-size: 14px;
  line-height: 26px;
}
.rc-time-picker-clear {
  top: 25%;
}
.time-picker--wrapper {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
}

.time-picker-icon {
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  width: 14px;
  height: 14px;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
