@import '../../../style/variables';
@import '../../../style/font-family';

@-webkit-keyframes uil-ring-anim {
  0% {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}
@-ms-keyframes uil-ring-anim {
  0% {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}
@-moz-keyframes uil-ring-anim {
  0% {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}
@-o-keyframes uil-ring-anim {
  0% {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}
@keyframes uil-ring-anim {
  0% {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

.login-form {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;

  background-color: $white-color;
  border-radius: 5px;
  box-shadow: $login-card-box-shadow;
  margin: 24px 0;
  padding: 30px 30px 45px;

  .card-title {
    @include set-typeface('Libre-medium');
    color: $text-color;
    font-size: 16px;
    line-height: 29px;
    margin-bottom: 23px;
  }

  .warning-message {
    @include set-typeface('Libre-medium');
    color: $error-color;
    display: none;
    font-size: 14px;
    line-height: 22px;
    margin-top: 8px;
    text-align: center;
    &.show-warning {
      display: block;
    }
  }

  .submit-btn {
    width: 100%;
    margin-top: 20px;
    position: relative;
    &.disabled, &.loading{
      pointer-events: none; opacity: .5; outline: none; cursor: no-drop;
    }
    &.loading:before{
      width: 20px; height: 24px; border-radius: 50%; position: absolute; left: 20px;
      border-right: 2px solid #fff; border-left: 2px solid #fff; content: '';
      -ms-animation: uil-ring-anim 1s linear infinite;
      -moz-animation: uil-ring-anim 1s linear infinite;
      -webkit-animation: uil-ring-anim 1s linear infinite;
      -o-animation: uil-ring-anim 1s linear infinite;
      animation: uil-ring-anim 1s linear infinite;
    }
  }
  

  .input {
    margin: 8px 0;
  }
}
