@import '../../../../style/variables';

.week-day-selector {
  display: flex;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;

  .week-day {
    width: 30px;
    height: 30px;
    line-height: 30px;
    box-sizing: border-box;

    margin-right: 8px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid $grey;
    background: $white-color;

    &.editable {
      cursor: pointer;
    }

    &.selected {
      border-color: transparent;
      background: $yellow;
      color: $white-color;
    }
  }
}
