.auth-routes {
  display: flex;

  .side-nav {
    min-height: calc(100vh - 72px);
    height: 100%;
    width: 222px;
  }

  .main-container {
    width: 100%;
    overflow: scroll;
    height: calc(100vh - 72px);
    position: relative;
  }
}
